@media print {
	header, 
	footer,
	section.OverlayAgeLanguage {
		@apply hidden #{!important};
	}
}

@page 
{
	size: A4 Portrait;
	margin: 0mm;
}

section.PDFSpecSheet {
	div.Content {
		@apply w-[21cm] aspect-[21/29.7];
		@apply p-0;
		@apply border-[1px] border-gray-500;

		#PDFTasteProfile {
			text {
				font-size: 0.8rem !important; 
				line-height: 100%  !important;
				hyphens: auto !important;
			}
		}

	}
	
	@media print {
		@apply p-0 m-0;

		.ButtonWrapper {
			@apply hidden;
		}

		div.Content {
			@apply w-screen h-screen max-w-[100vw];
			@apply border-0;
		}
	} 
}