.Button {
	@apply p-[1.6rem] md:p-[1.4rem] rounded-[0.4rem];
	@apply flex items-center justify-center text-center;
	@apply outline-none;
	// @apply transition-all duration-200;
	@apply no-underline #{!important};

	// &:hover:not(.language-select) {
	// 	@apply translate-y-[-2px];
	// }

	&.small {
		@apply p-[1rem] md:p-[1rem];
	}

	&.light {
		@apply bg-solid-white text-solid-black border-solid-white border-[1px];

		&.outline {
			@apply bg-transparent text-solid-white border-solid-white border-[1px];
		}
	}
	
	&.dark {
		@apply bg-primary-charcoal text-solid-white border-primary-charcoal border-[1px];

		&.outline {
			@apply bg-transparent text-primary-charcoal border-primary-charcoal border-[1px];
		}
	}

	&.charcoal {
		@apply bg-primary-clay text-solid-white border-primary-clay border-[1px];
	}

	&.language-select {
		&:hover {
			& > span {
				@apply bg-primary-charcoal;
			}
		}
	}

	&[disabled] {
		@apply opacity-30 pointer-events-none;
	}
}

// a:hover {
// 	text-decoration: none !important;
// }