html {
   font-size: 62.5%;
   scroll-behavior: smooth;

	 body {
      -webkit-font-smoothing: antialiased;
			@apply font-[1.6rem];
			@apply font-circular;
         -webkit-print-color-adjust:exact !important;
         print-color-adjust:exact !important;

			main {
				@apply max-w-[100vw] overflow-hidden;
			}
	 }
}

.page-width {
	@apply w-full max-w-[1280px] mx-auto;
	@apply px-[2.8rem];

   &.narrow {
      @apply max-w-[1050px]
   }
}

[hide-scrollbar] {
   &::-webkit-scrollbar {
      display: none;
   }

   /* Hide scrollbar for IE, Edge and Firefox */
   & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
   }
}

[data-disabled="true"] {
   @apply opacity-50;
   @apply pointer-events-none #{!important};
}

*:not(.product-options-item, input[type="text"], input[type="email"], input[type="tel"], textarea):focus {
  @apply outline-primary-clay ring-transparent border-0 #{!important};
}


.People {
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
}

.People::-webkit-scrollbar {
   display: none;
 }