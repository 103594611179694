.Display,
h1.h1,
h2.h2,
h3.h3,
h4.h4,
h5.h5,
h6.h6,
.Quote,
.Copy,
.Caption {
	@apply text-neutral-700;
}

.Display {
	/* Headings/Display */
	font-family: "SangBleu Kingdom";
	font-size: 9.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 9.6rem */
	letter-spacing: -0.192rem;

	@apply md:text-[4.8rem] tracking-[-0.096rem];
}

h1, .h1 {
	/* Headings/H1 */
	font-family: "SangBleu Kingdom";
	font-size: 6.4rem;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 6.4rem */
	letter-spacing: -0.128rem;

	@apply md:text-[4.0rem] tracking-[-0.08rem];
}

h2, .h2 {
	/* Headings/H2 */
	font-family: "SangBleu Kingdom";
	font-size: 4.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 100%; /* 4.8rem */
	letter-spacing: -0.048rem;

	@apply md:text-[3.2rem] tracking-[-0.036rem];

	&.PDF {
		font-family: "SangBleu Kingdom";
		font-size: 3.4rem;
	}
}

h3, .h3 {
	/* Headings/H3 */
	font-family: "SangBleu Kingdom";
	font-size: 3.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 4.32rem */

	@apply md:text-[3rem];
}

h4, .h4 {
	/* Headings/H4 */
	font-family: "SangBleu Kingdom";
	font-size: 2.8rem;
	font-style: normal;
	font-weight: 500;
	line-height: 115%; /* 3.22rem */
	letter-spacing: 0.028rem;
}

h5, .h5 {
	/* Headings/H5 */
	font-family: "Circular Std";
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 500;
	line-height: 115%; /* 2.76rem */
}

h6, .h6 {
	/* Headings/H6 */
	font-family: "Circular Std";
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 135%; /* 2.16rem */
	text-transform: uppercase;
}

.Quote {
	/* Body/Quote */
	font-family: "SangBleu Kingdom";
	font-size: 3.6rem;
	font-style: italic;
	font-weight: 400;
	line-height: 120%; /* 4.32rem */

	@apply md:text-[2.8rem];
}

.Copy {
	/* Body/Copy */
	font-family: "Circular Std";
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 450;
	line-height: 130%; /* 2.08rem */
	letter-spacing: -0.032rem;

	&.Big {
		/* Body/Copy Big */
		font-family: "Circular Std";
		font-size: 1.8rem;
		font-style: normal;
		font-weight: 450;
		line-height: 125%; /* 2.25rem */
	}

	&.Small {
		/* Body/Copy Small */
		font-family: "Circular Std";
		font-size: 1.4rem;
		font-style: normal;
		font-weight: 450;
		line-height: 150%; /* 2.1rem */
	}

}

.PDF {
	font-family: "Circular Std";
	font-size: 1.1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 2.1rem */

	&.Small {
		font-size: 0.9rem;
	}

	&.Mini {
		font-size: 0.8rem;
	}

	&.Medium {
		font-weight: 500;
	}

	&.Capfont {
		font-family: "ITC Blair";
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 1.68rem */
		letter-spacing: 0.018rem;
		text-transform: uppercase;
		font-size: 0.8rem;
	}

	&.Primary {
		font-family: "SangBleu Kingdom";
		line-height: 115%; /* 2.08rem */
		letter-spacing: -0.016rem;
		font-style: italic;
		font-size: 1.15rem;
	}
}

.Button,
.ButtonText {
	/* Body/Button Text */
	font-family: "ITC Blair";
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 1.68rem */
	letter-spacing: 0.018rem;
	text-transform: uppercase;
}

.Caption {
	/* Body/Caption */
	font-family: "ITC Blair";
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 500;
	line-height: 130%; /* 1.2rem */
	text-transform: uppercase;
}


div.Richtext {
	p {
		all: unset;
		@apply block;
	}

	&:not(.h1, .h2, .h3, .h4, .h5, .h6, .Caption, .Button, .Display, .Quote ) > p {
		@apply mb-[12px];
	}
}