.height-full {
	min-height: calc(100vh - var(--header-height));
}
.height-two-thirds {
	height: 66.66vh;
}
.height-half {
	height: 50vh;
}

.image-left {
	@apply order-[-1];
}

img {
	// @apply bg-primary-offwhite;
}