
div.mce_inline_error {
    @apply text-indicator-error bg-transparent #{!important};
 }
 
 input.mce_inline_error {
    @apply border-indicator-error #{!important};
 }

 section.Contact, fieldset.Profile, fieldset.Addresses {
     input[type="text"], input[type="email"], input[type="tel"], textarea {
        &:focus-visible, &:focus, &:active, &:hover {
            @apply outline-transparent ring-transparent border-b-primary-charcoal transition-all duration-200 #{!important};
        }
     }
 }
