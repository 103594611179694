.Display,
h1.h1,
h2.h2,
h3.h3,
h4.h4,
h5.h5,
h6.h6,
.Quote,
.Copy,
.Caption {
  @apply text-neutral-700;
}

.Display {
  /* Headings/Display */
  font-family: "SangBleu Kingdom";
  font-size: 9.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 9.6rem */
  letter-spacing: -0.192rem;
  @apply md:text-[4.8rem] tracking-[-0.096rem];
}

h1, .h1 {
  /* Headings/H1 */
  font-family: "SangBleu Kingdom";
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 6.4rem */
  letter-spacing: -0.128rem;
  @apply md:text-[4.0rem] tracking-[-0.08rem];
}

h2, .h2 {
  /* Headings/H2 */
  font-family: "SangBleu Kingdom";
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 4.8rem */
  letter-spacing: -0.048rem;
  @apply md:text-[3.2rem] tracking-[-0.036rem];
}
h2.PDF, .h2.PDF {
  font-family: "SangBleu Kingdom";
  font-size: 3.4rem;
}

h3, .h3 {
  /* Headings/H3 */
  font-family: "SangBleu Kingdom";
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 4.32rem */
  @apply md:text-[3rem];
}

h4, .h4 {
  /* Headings/H4 */
  font-family: "SangBleu Kingdom";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 3.22rem */
  letter-spacing: 0.028rem;
}

h5, .h5 {
  /* Headings/H5 */
  font-family: "Circular Std";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 2.76rem */
}

h6, .h6 {
  /* Headings/H6 */
  font-family: "Circular Std";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 2.16rem */
  text-transform: uppercase;
}

.Quote {
  /* Body/Quote */
  font-family: "SangBleu Kingdom";
  font-size: 3.6rem;
  font-style: italic;
  font-weight: 400;
  line-height: 120%; /* 4.32rem */
  @apply md:text-[2.8rem];
}

.Copy {
  /* Body/Copy */
  font-family: "Circular Std";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 2.08rem */
  letter-spacing: -0.032rem;
}
.Copy.Big {
  /* Body/Copy Big */
  font-family: "Circular Std";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 450;
  line-height: 125%; /* 2.25rem */
}
.Copy.Small {
  /* Body/Copy Small */
  font-family: "Circular Std";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 450;
  line-height: 150%; /* 2.1rem */
}

.PDF {
  font-family: "Circular Std";
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 2.1rem */
}
.PDF.Small {
  font-size: 0.9rem;
}
.PDF.Mini {
  font-size: 0.8rem;
}
.PDF.Medium {
  font-weight: 500;
}
.PDF.Capfont {
  font-family: "ITC Blair";
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 1.68rem */
  letter-spacing: 0.018rem;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.PDF.Primary {
  font-family: "SangBleu Kingdom";
  line-height: 115%; /* 2.08rem */
  letter-spacing: -0.016rem;
  font-style: italic;
  font-size: 1.15rem;
}

.Button,
.ButtonText {
  /* Body/Button Text */
  font-family: "ITC Blair";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.68rem */
  letter-spacing: 0.018rem;
  text-transform: uppercase;
}

.Caption {
  /* Body/Caption */
  font-family: "ITC Blair";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 1.2rem */
  text-transform: uppercase;
}

div.Richtext p {
  all: unset;
  @apply block;
}
div.Richtext:not(.h1, .h2, .h3, .h4, .h5, .h6, .Caption, .Button, .Display, .Quote) > p {
  @apply mb-[12px];
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
html body {
  -webkit-font-smoothing: antialiased;
  @apply font-[1.6rem];
  @apply font-circular;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
html body main {
  @apply max-w-[100vw] overflow-hidden;
}

.page-width {
  @apply w-full max-w-[1280px] mx-auto;
  @apply px-[2.8rem];
}
.page-width.narrow {
  @apply max-w-[1050px];
}

[hide-scrollbar] {
  /* Hide scrollbar for IE, Edge and Firefox */
}
[hide-scrollbar]::-webkit-scrollbar {
  display: none;
}
[hide-scrollbar] {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

[data-disabled=true] {
  @apply opacity-50;
  @apply pointer-events-none !important;
}

*:not(.product-options-item, input[type=text], input[type=email], input[type=tel], textarea):focus {
  @apply outline-primary-clay ring-transparent border-0 !important;
}

.People {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.People::-webkit-scrollbar {
  display: none;
}

.Button {
  @apply p-[1.6rem] md:p-[1.4rem] rounded-[0.4rem];
  @apply flex items-center justify-center text-center;
  @apply outline-none;
  @apply no-underline !important;
}
.Button.small {
  @apply p-[1rem] md:p-[1rem];
}
.Button.light {
  @apply bg-solid-white text-solid-black border-solid-white border-[1px];
}
.Button.light.outline {
  @apply bg-transparent text-solid-white border-solid-white border-[1px];
}
.Button.dark {
  @apply bg-primary-charcoal text-solid-white border-primary-charcoal border-[1px];
}
.Button.dark.outline {
  @apply bg-transparent text-primary-charcoal border-primary-charcoal border-[1px];
}
.Button.charcoal {
  @apply bg-primary-clay text-solid-white border-primary-clay border-[1px];
}
.Button.language-select:hover > span {
  @apply bg-primary-charcoal;
}
.Button[disabled] {
  @apply opacity-30 pointer-events-none;
}

.height-full {
  min-height: calc(100vh - var(--header-height));
}

.height-two-thirds {
  height: 66.66vh;
}

.height-half {
  height: 50vh;
}

.image-left {
  @apply order-[-1];
}

.product-form select {
  -webkit-appearance: none;
}

div.mce_inline_error {
  @apply text-indicator-error bg-transparent !important;
}

input.mce_inline_error {
  @apply border-indicator-error !important;
}

section.Contact input[type=text]:focus-visible, section.Contact input[type=text]:focus, section.Contact input[type=text]:active, section.Contact input[type=text]:hover, section.Contact input[type=email]:focus-visible, section.Contact input[type=email]:focus, section.Contact input[type=email]:active, section.Contact input[type=email]:hover, section.Contact input[type=tel]:focus-visible, section.Contact input[type=tel]:focus, section.Contact input[type=tel]:active, section.Contact input[type=tel]:hover, section.Contact textarea:focus-visible, section.Contact textarea:focus, section.Contact textarea:active, section.Contact textarea:hover, fieldset.Profile input[type=text]:focus-visible, fieldset.Profile input[type=text]:focus, fieldset.Profile input[type=text]:active, fieldset.Profile input[type=text]:hover, fieldset.Profile input[type=email]:focus-visible, fieldset.Profile input[type=email]:focus, fieldset.Profile input[type=email]:active, fieldset.Profile input[type=email]:hover, fieldset.Profile input[type=tel]:focus-visible, fieldset.Profile input[type=tel]:focus, fieldset.Profile input[type=tel]:active, fieldset.Profile input[type=tel]:hover, fieldset.Profile textarea:focus-visible, fieldset.Profile textarea:focus, fieldset.Profile textarea:active, fieldset.Profile textarea:hover, fieldset.Addresses input[type=text]:focus-visible, fieldset.Addresses input[type=text]:focus, fieldset.Addresses input[type=text]:active, fieldset.Addresses input[type=text]:hover, fieldset.Addresses input[type=email]:focus-visible, fieldset.Addresses input[type=email]:focus, fieldset.Addresses input[type=email]:active, fieldset.Addresses input[type=email]:hover, fieldset.Addresses input[type=tel]:focus-visible, fieldset.Addresses input[type=tel]:focus, fieldset.Addresses input[type=tel]:active, fieldset.Addresses input[type=tel]:hover, fieldset.Addresses textarea:focus-visible, fieldset.Addresses textarea:focus, fieldset.Addresses textarea:active, fieldset.Addresses textarea:hover {
  @apply outline-transparent ring-transparent border-b-primary-charcoal transition-all duration-200 !important;
}

div.Richtext * {
  color: unset !important;
}

@media print {
  header,
  footer,
  section.OverlayAgeLanguage {
    @apply hidden !important;
  }
}
@page {
  size: A4 Portrait;
  margin: 0mm;
}
section.PDFSpecSheet div.Content {
  @apply w-[21cm] aspect-[21/29.7];
  @apply p-0;
  @apply border-[1px] border-gray-500;
}
section.PDFSpecSheet div.Content #PDFTasteProfile text {
  font-size: 0.8rem !important;
  line-height: 100% !important;
  hyphens: auto !important;
}
@media print {
  section.PDFSpecSheet {
    @apply p-0 m-0;
  }
  section.PDFSpecSheet .ButtonWrapper {
    @apply hidden;
  }
  section.PDFSpecSheet div.Content {
    @apply w-screen h-screen max-w-[100vw];
    @apply border-0;
  }
}

/*# sourceMappingURL=SCSS.css.map */
